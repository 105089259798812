@if(!isMinimized && !isPro){
<div class="bg-dark-gradient text-light rounded mt-5 mb-5 px-5 pt-5 pb-2">
    <h1 class="text-success">Rentability Pro</h1>
    <p class="mb-5">Upgrade to list unlimited properties!</p>

    <div class="d-flex justify-content-between">
        <div>
            <button class="btn btn-outline-primary me-3 rounded-pill" type="button" (click)="onMonthly()">
                <span class="px-5">$15.00/month*</span></button>
            <button class="btn bg-bright-gradient rounded-pill fw-bold" type="button" (click)="onYearly()">
                <span class="px-3">$150.00/year (2 months free)*</span></button>
        </div>
        <div>
            <input id="referralCode" type="text" class="form-control bg-dark text-light" [(ngModel)]="referralCode"
                placeholder="Referral Code" />
        </div>
        <p class="text-end opacity-75">* Subscriptions automatically renew</p>
    </div>
</div>
}

@if(!isPro && isMinimized){
<div class="bg-dark-gradient text-light rounded m-1 p-3">
    <h5 class="text-success">Rentability Pro</h5>
    <a class="mb-5 text-light" [routerLink]="['/pricing']" href="/pricing">Learn about the benefits of upgrading to a
        Pro account</a>
</div>
}

@if(isPro && !isExpiring){
<div class="bg-dark-gradient text-light rounded px-2 py-2">
    <strong class="text-success">Rentability Pro</strong>
    <p class="">You are enjoying all the benefits of our pro account.</p>
    <a class="btn btn-outline-primary ms-auto" type="button" (click)="onManageSubscription()">Manage Subscription</a>
</div>
}

@if(isPro && isExpiring){
<div class="bg-dark-gradient text-light roundedpx-5 pt-5 pb-2">
    <strong class="text-success">Rentability Pro</strong>
    <p class="mb-5">You are enjoying all the benefits of our pro account. Your pro subscription expires on: {{expiryDate
        | date}}</p>
</div>
}