import { DatePipe, LocationStrategy } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { tap } from 'rxjs';
import { PaymentService } from 'src/app/api/services';
import { AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upgrade',
  standalone: true,
  imports: [DatePipe, RouterModule, FormsModule],
  templateUrl: './upgrade.component.html',
  styleUrl: './upgrade.component.scss'
})
export class UpgradeComponent implements OnInit {
  private paymentService = inject(PaymentService);
  private locationStrategy = inject(LocationStrategy);
  private authService = inject(AuthService);
  private router = inject(Router);

  destroyRef = inject(DestroyRef);
  environment = environment;

  planId = '5e98f00a-6712-41d4-8228-c587dc5257f6';
  referralCode: string = '';
  @Input() isMinimized: boolean = false;

  isPro = false;

  get isExpiring(): boolean {
    return false;
  }

  get isLandlord(): boolean {
    return this.authService.isUserLandlord();
  }

  expiryDate: Date | null = null;
  ngOnInit(): void {
    this.referralCode = localStorage.getItem('referralId') ?? '';
    
    this.authService.isUserPro().pipe(
      tap(res=>this.isPro = res),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  onMonthly() {
    const successUrl = environment.url + this.locationStrategy.prepareExternalUrl(this.router.createUrlTree(['/success']).toString());
    this.paymentService.createSubscription({planId: this.planId, isYearly: false,}).pipe(
      tap(res => window.location.href = res.url!),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  onYearly() {
    const successUrl = environment.url + this.locationStrategy.prepareExternalUrl(this.router.createUrlTree(['/success']).toString());
    this.paymentService.createSubscription({planId: this.planId, isYearly: true,}).pipe(
      tap(res => window.location.href = res.url!),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  onManageSubscription(){
    this.paymentService.getBillingPortalUrl().pipe(
      tap(res => window.location.href = res.url!),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
